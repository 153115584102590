<template>
  <div>
    <global-navigation
      :primary-button="{
        isVisible: true,
        label: 'Log in',
        link: 'https://id.anaplan.com/',
      }"
      :navigation="getCustomNavigation()"
    />
    <div class="grid-container--registration">
      <div class="grid-item--form">
        <div class="inline-message">
          <svg-icon icon="validation-info" class="icon icon--info" />
          <p>
			This page has been moved, and you're being redirected to the new page. If this page doesn't refresh automatically, use this link:

            <a class="link" href="https://support.anaplan.com/create-account"
              >Create new account</a
            >.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const redirectFunction = ()=>{
	window.location.href= process.env.VUE_APP_REDIRECT
}

if (process.env.VUE_APP_REDIRECT !== undefined) {
  setTimeout(()=>{redirectFunction()
  },5000)
}

import iconSprite from "@anaplan/design-system-icons/images/icon-sprite.svg";
import registerIcons from "@anaplan/design-system-icons";
registerIcons("svg-icon", iconSprite)();

import "./assets/scss/styles.scss";

export default {
  name: "okta-user-ui",

  methods: {
    getCustomNavigation: function() {
      return [
        {
          title: "Community",
          href: "https://community.anaplan.com/",
        },
        {
          title: "Learning Center",
          href: "https://learning.anaplan.com/",
        },
        {
          title: "Groups",
          href: "https://community.anaplan.com/categories/groups",
        },
        {
          title: "Support",
          href: "https://support.anaplan.com/",
        },
      ];
    }
  },
};
</script>

<!-- Globally import the Gnav's CSS from the NPM package. -->
<style src="@anaplan/global-navigation/dist/global-navigation.css" />

<style lang="scss">
/* These additional styles are not yet available from ADS, and should be
	removed once they are. */

form.form div.select select.select__control--error {
  box-shadow: 0 0 0 1px #db3743;
}

form.form div.select ~ strong.input__message--error {
  visibility: visible;
}

.introWrapper {
  text-align: left;
  width: 100%;
  max-width: 100%;

  h1 {
    margin-bottom: 24px;
    margin-top: 22px;
  }
}

.alreadyMember {
  h1 {
    display: inline;
    margin-right: 16px;
  }

  p {
    display: inline;
  }
}

.button--createAccount {
  white-space: nowrap;
}

form.form fieldset.captchaWrapper {
  margin-top: 16px;
}

.grid-container--registration {
  .grid-item--form {
    padding: 90px;
  }
}

@media screen and (max-width: 979px) {
  .grid-container--registration {
    .grid-item--form {
      padding: 80px 40px;
    }
  }
}

@media screen and (min-width: 979px) {
  .grid-container--registration {
    max-width: 1200px;
    margin: 0 auto;

    .grid-item--form {
      padding: 120px 90px 100px 90px;
    }
  }
}

.orBar {
  margin: 48px 0;

  h2 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #999;
    color: #999;
    line-height: 0.1em;
    margin: 10px 0 20px;

    span {
      background: #fff;
      padding: 0 10px;
    }
  }
}

.grid-container--stepBadges {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 48px;
  margin: 16px 0;
  white-space: nowrap; /* Keeps the step header and badge content on one line at all times */

  span.badge {
    background-color: #3c67ea;
    color: white;
    border: 0;
    margin-right: 4px;
    display: inline-block;
    min-width: 16px;
    text-align: center;
  }

  h2 {
    display: inline;
    font-weight: bold;
  }

  p,
  ul {
    margin: 0 0 0 24px;
    font-size: smaller;
    line-height: 120%;
    white-space: normal;
  }

  ul {
    margin-left: 0;
  }
}

@media screen and (max-width: 720px) {
  /* smallest size before the steps wrap and look bad */
  .grid-container--stepBadges {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }
}

@media screen and (max-width: 600px) {
  .grid-container {
    display: block;
  }
}

.grid-container--createAccountFooter {
  display: grid;
  grid-template-columns: auto 99fr; /* Keep text adjacent to button */
  grid-template-rows: 1fr;
  gap: 16px;
  grid-template-areas: "cA_b cA_t";
  margin-top: 16px;

  .createAccount__button {
    grid-area: cA_b;
  }

  .createAccount__text {
    grid-area: cA_t;
    font-size: small;
  }

  .createAccount__text > p {
    margin: 0;
  }
}

@media screen and (max-width: 1199px) {
  .grid-container--createAccountFooter {
    display: block;

    .createAccount__text {
      margin-top: 16px;
    }
  }
}

.modal__body.modal__body--inset p:first-child {
  margin-top: 0;
}
</style>
