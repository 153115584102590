import Vue from 'vue'
import App from './App.vue'

// Global Navigation
	const GlobalNavigation = require('@anaplan/global-navigation')
	Vue.use(GlobalNavigation)

Vue.config.productionTip = false

new Vue({
	render: h => h(App)
}).$mount('#app')
